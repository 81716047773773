import { DialogProps } from '@mui/material/Dialog';
import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ForestIcon from '@mui/icons-material/Forest';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function JoinTheClub(props: DialogProps) {
  const [first, setFirst] = useState(true);
  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onClose !== undefined) {
      props.onClose(event, "backdropClick")
    }
    setFirst(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.open}
        onClose={handleClose}
        TransitionComponent={props.TransitionComponent}
      >
      <Container component="main" maxWidth="xs"
          sx={{
            //backgroundImage: `url("https://cdn.vectorstock.com/i/1000x1000/23/77/tall-tree-white-background-vector-23972377.webp")`,
            backgroundImage: `url("./tree-bg.jpg")`,
            backgroundRepeat: 'repeat',
            backgroundSize: '180%',
            backgroundPositionX: '68%',  
            backgroundPositionY: '-44%',  
          }}
        >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Typography
              component="h1"
              color="secondary"
              sx={{ fontSize: 24, fontWeight: 900, flexGrow: 1 }}
            >
              THE $89 TREE TRIM CLUB
            </Typography>
       <Box component='div' sx={{display:'flex', flexDirection:'column', alignItems:'start', width: '100%', borderRadius:8, p:3, mt:3, backgroundColor:'rgba(255, 255, 255, 0.90)', borderColor:'black', borderWidth:'2px', borderStyle:'dotted'}}>
            <Typography
              component="h1"
              color="inherit"
              sx={{ mb: 2, fontSize: 20, fontWeight: 700, flexGrow: 1 }}
            >
              Did you know? 
            </Typography>Home owners in your neighborhood spend an average of:
            <Typography
              component="h1"
              color="inherit"
              sx={{ mt: 2,fontWeight: 500, flexGrow: 1 }}
            >
 <Typography display="inline" sx={{fontSize:20, fontWeight: 900}} color="secondary">$1100</Typography> on regular tree services and maintenance each year
            </Typography>
            <Box display="flex" width="100%" justifyContent="center">
            <Typography
              component="h1"
              color="inherit"
              sx={{ my: 1, fontSize: 20, fontWeight: 700}}
            >
              PLUS...
            </Typography>
            </Box>
            <Typography
              component="h1"
              color="inherit"
              sx={{ fontWeight: 500, flexGrow: 1 }}
            >
              <Typography display="inline" sx={{fontSize:20, fontWeight: 900}} color="secondary">$1450</Typography> on tree services after a tree-impacting weather event
            </Typography>
           </Box>
       <Box component='div' sx={{display:'flex', flexDirection:'column', alignItems:'start', width: '100%', p:3, }}>
          <Typography sx={{mt:0, mr: 3}} component="h1" variant="subtitle1">
            With tree-impacting weather events happening more and more often, it's becoming the #1 home owner expense.
            </Typography>
          <Typography sx={{mt:3}} component="h1" variant="subtitle1">
Isn't it time you had a better way
          </Typography>
          <Typography sx={{mr:2}} component="h1" variant="subtitle1">
to prepare for these *big* expenses?
          </Typography>
           </Box>
       <Box component='div' sx={{display:'flex', flexDirection:'column', alignItems:'center', width: '100%', borderRadius:8, p:3, mb:3, backgroundColor:'rgba(255, 255, 255, 0.90)', borderColor:'black', borderWidth:'2px', borderStyle:'dotted'}}>
            <Button
              onClick={e => handleClose(e)}
              type="submit"
              color='secondary'
              fullWidth
              variant="outlined"
              sx={{fontSize: 18, fontWeight: 700, borderRadius:20, mb:1}}
            >
              Join the $89 Tree Trim Club
            </Button>
          <Typography component="h1"> for only <Typography display="inline" sx={{fontSize:20, fontWeight: 900}} color="secondary">$89/month</Typography>:
          </Typography>
          <Typography sx={{mt:2}} component="h1" variant="subtitle1">
Get 2 pre-scheduled appointments a year for regular tree maintenance and servicing (up to 10 trees)
          </Typography>
          <Typography sx={{mt:2}} component="h1" variant="subtitle1">
Always get priority scheduling for service, even after big storms and weather events
          </Typography>
          <Typography sx={{mt:2}} component="h1" variant="subtitle1">
In the event of a tree-impacting weather event, save up to $250 instantly on tree cleanup
          </Typography>
          <Typography sx={{my:2}} component="h1" variant="subtitle1">
Plus, split your final balance into payments added to your monthly bill for no added fee
          </Typography>
            <Button
              onClick={e => handleClose(e)}
              type="submit"
              color='secondary'
              fullWidth
              variant="contained"
            >
              JOIN NOW !!
            </Button>
       </Box>
       <Box component='div' sx={{display:'flex', flexDirection:'column', alignItems:'start', width: '100%', borderRadius:8, p:3, mb:3, backgroundColor:'rgba(255, 255, 255, 0.90)', borderColor:'black', borderWidth:'2px', borderStyle:'dotted'}}>
            <Button
              onClick={e => handleClose(e)}
              type="submit"
              color='warning'
              fullWidth
              variant="outlined"
              sx={{fontSize: 18, fontWeight: 700, borderRadius:20, mb:1}}
            >
              Join the $99 Tree Trim Club
            </Button>
          <Typography sx={{mt:2}} component="h1"> or, for only <Typography display="inline" sx={{fontSize:20, fontWeight: 900}} color="warning">$99/month</Typography>:
          </Typography>
          <Typography sx={{my:2}} component="h1" variant="subtitle1">
In the event of a tree-impacting weather event, save up to $500 instantly on tree cleanup
          </Typography>
            <Button
              onClick={e => handleClose(e)}
              type="submit"
              color='warning'
              fullWidth
              variant="contained"
            >
              JOIN PREMIUM CLUB!!
            </Button>
       </Box>
       <Box component='div' sx={{display:'flex', flexDirection:'column', alignItems:'start', width: '100%', borderRadius:8, p:3, mb:3, backgroundColor:'rgba(255, 255, 255, 0.90)', borderColor:'black', borderWidth:'2px', borderStyle:'dotted'}}>
            <Box sx={{width:'100%', mt:-5, display:'flex', justifyContent:'center'}}>
            <Button
              color='primary'
              variant="contained"
              size="small"
            >
              <Typography sx={{fontSize:12, fontWeight:600}}>
                NEVER WORRY ABOUT YOUR TREES AGAIN
              </Typography>
            </Button>
            </Box>
            <Button
              onClick={e => handleClose(e)}
              type="submit"
              color='primary'
              fullWidth
              variant="outlined"
              sx={{fontSize: 18, fontWeight: 700, borderRadius:20, mt:2, mb:1}}
            >
              Join the $129 Tree Trim Club
            </Button>
          <Typography sx={{mt:2}} component="h1"> or, for only <Typography display="inline" sx={{fontSize:20, fontWeight: 900}} color="primary">$129/month</Typography>:
          </Typography>
          <Typography sx={{my:2}} component="h1" variant="subtitle1">
In the event of a tree-impacting weather event, get all tree cleanup for FREE
          </Typography>
            <Button
              onClick={e => handleClose(e)}
              type="submit"
              color='primary'
              fullWidth
              variant="contained"
            >
              JOIN DIAMOND CLUB!!
</Button>
</Box>
          </Box>
      </Container>
      </Dialog>
    </React.Fragment>
  );
}
//send us your available timeslots and we'll text you a confirmed tree trimming appointment.
