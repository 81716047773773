import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import CelebrationIcon from '@mui/icons-material/Celebration';

const steps = [
  {
    label: 'Video-powered quote submitted',
    description: `The quote you created was submitted to our network. We will review and send an estimate back shortly. Sit tight, if you don't receive an estimate within 24 hrs, contact us at info@treetrimming.co.`,
    subTitle: 'Less than 24 hrs until estimate'
  },
  {
    label: 'Quote assigned to arborist for review',
    description:
      'Your quote has been assigned to one of our arborists, they will  assess the number of cuts, difficultly of each cut and the overall job, and calculate a total price. If they cannot estimate the job or have questions, you will be notified here in the app.',
    subTitle: 'Less than 12 hrs until estimate'
  },
  {
    label: 'Estimate generated and sent back',
    description: "The estimate for your quote is ready, click below to review it. If you are satisfied with the price click to schedule service. Note, if the quote you made doesn't accurately reflect the actual work involved, the arborist may ask you to confirm an updated estimate upon arrival.",
    subTitle: "Last step, you're ready to go!"
  },
];

export default function Status() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ p:3, maxWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{mb:3}} variant="h6">Congrats!!</Typography>
          <Avatar sx={{ m: 1, mb: 3, bgcolor: 'secondary.main' }}>
            <CelebrationIcon />
          </Avatar>
          <Typography sx={{mb:1}} variant="h6">Your quote has been submitted</Typography>
          <Typography sx={{mb:3, mx:2.3}} variant="body2">In a few moments, our expert team will get to work on how to make your trees and surrounding area look their best.</Typography>
          <Typography sx={{mb:3, fontWeight:600}} variant="h6">STATUS</Typography>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                  <Typography variant="caption">{step.subTitle}</Typography>
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography variant="body2">{step.description}</Typography>
              <Box sx={{ mt:1, mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? 'Review Estimate' : 'Update Quote'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
